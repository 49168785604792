import { fetchApi } from "@shared/lib";
import { toast } from "sonner";

export const fetchContestParticipants = async (contestId: string) => {
  try {
    const response = await fetchApi<Api.ContestParticipants>(`/api/contests/participants/${contestId}`);
    return response.data;
  } catch (error) {
    toast.error("There was an error fetching contest participants. Please try again in a few minutes.");
    throw error;
  }
};
